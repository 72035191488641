import { RouteRecordRaw, Router } from "vue-router"
import Index from '../views/dg6789admin6868/Index.vue'
import routesExample from "./example"
import client from "./client"
const routesAdmin: Array<RouteRecordRaw> = [
  routesExample,
  {
    path: '/home',
    name: 'home',
    component: Index,
    meta: {
      requiresAuth: true,
      breadcrumb: [{ '/admin': 'Home' }]
    }
    ,
    children: [
      {
        path: '/index',
        name: 'index',
        component: () =>
          import('../views/dg6789admin6868/admin/manageRoom/ManageRoom.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '', name: 'Danh sách phòng', status: false }]
        },
      }, {
        path: '/userlist',
        name: 'userlist',
        component: () =>
          import('../views/dg6789admin6868/user/UserList.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/user', name: 'Người sử dụng', status: false }]
        },
      }, {
        path: '/inform',
        name: 'Inform',
        component: () =>
          import('../views/dg6789admin6868/user/UserInform.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ '/home': 'Home', '/user': 'Người sử dung', '': 'Danh sách client' }]
        }
      }, {
        path: '/user-detail',
        name: 'user-detail',
        component: () =>
          import('../views/dg6789admin6868/user/UserDetail.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ '/home': 'Home', '/user': 'Người sử dung', '': 'Danh sách client' }]
        }
      }, {
        path: '/user-create',
        name: 'user-create',
        component: () =>
          import('../views/dg6789admin6868/user/UserCreate.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ '/home': 'Home', '/user': 'Người sử dung', '': 'Danh sách client' }]
        }
      }, {
        path: '/change-password',
        name: 'change-password',
        component: () =>
          import('../views/dg6789admin6868/user/ChangePassword.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ '/home': 'Home', '/user': 'Người sử dung', '': 'Thay đổi mật khẩu' }]
        }
      }, {
        path: '/manager',
        name: 'manager',
        component: () =>
          import('../views/dg6789admin6868/admin/report/MoneyManager.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/user', name: 'Người sử dụng', status: false }]
        },
      }, {
        path: '/manager-pdf',
        name: 'managerPdf',
        component: () =>
          import('../views/dg6789admin6868/admin/report/ViewPayList.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/user', name: 'Xuất file thông báo', status: false }]
        },
      }, {
        path: '/cost',
        name: 'cost',
        component: () =>
          import('../views/dg6789admin6868/admin/cost/Cost.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/user', name: 'Người sử dụng', status: false }]
        },
      }, {
        path: '/blog',
        name: 'blog',
        component: () =>
          import('../views/dg6789admin6868/blog/BlogList.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/blog', name: 'Bài viết hướng dẫn', status: false }]
        },
      }, {
        path: '/blog-create',
        name: 'blog-create',
        component: () =>
          import('../views/dg6789admin6868/blog/BlogCreate.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/user', name: 'Người sử dụng', status: false }]
        },
      }, {
        path: '/blog-detail',
        name: 'blog-detail',
        component: () =>
          import('../views/dg6789admin6868/blog/BlogDetail.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/user', name: 'Người sử dụng', status: false }]
        },
      }, {
        path: '/blog-view',
        name: 'blog-view',
        component: () =>
          import('../views/dg6789admin6868/blog/BlogView.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/user', name: 'Người sử dụng', status: false }]
        },
      }, {
        path: '/huongdan',
        name: 'huong dan',
        component: () =>
          import('../views/dg6789admin6868/huongdan/HuongDan.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/huongdan', name: 'Hướng dẫn', status: false }]
        }
      }, {
        path: '/roles',
        name: 'roles',
        component: () =>
          import('../views/dg6789admin6868/admin/roles/RolesSystem.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/roles', name: 'Quyền người sử dụng', status: false }]
        }
      }, {
        path: '/news',
        name: 'news',
        component: () =>
          import('../views/dg6789admin6868/news/CreateNews.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/news', name: 'Đăng tin tìm khách', status: false }]
        }
      }, {
        path: '/advertisement',
        name: 'advertisement',
        component: () =>
          import('../views/dg6789admin6868/advertisement/AdvertisementList.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/advertisement', name: 'Quảng cáo', status: false }]
        },
      }, {
        path: '/area',
        name: 'area',
        component: () =>
          import('../views/dg6789admin6868/area/AreaList.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/area', name: 'Khu vực địa lý', status: false }]
        },
      }, {
        path: '/banks',
        name: 'banks',
        component: () =>
          import('../views/dg6789admin6868/banks/BanksList.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/banks', name: 'Ngân hàng', status: false }]
        },
      }, {
        path: '/building',
        name: 'building',
        component: () =>
          import('../views/dg6789admin6868/building/BuildingList.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/building', name: 'Toàn nhà', status: false }]
        },
      }, {
        path: '/building-detail',
        name: 'building-detail',
        component: () =>
          import('../views/dg6789admin6868/building/BuildingDetail.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ '/home': 'Home', '/building': 'Tòa nhà', '': 'Danh sách tòa nhà' }]
        }
      }, {
        path: '/building-create',
        name: 'building-create',
        component: () =>
          import('../views/dg6789admin6868/building/BuildingCreate.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ '/home': 'Home', '/building': 'Tòa nhà', '': 'Danh sách tòa nhà' }]
        }
      }, {
        path: '/building-business',
        name: 'building-business',
        component: () =>
          import('../views/dg6789admin6868/building/BuildingBusiness.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ '/home': 'Home', '/building': 'Tòa nhà', '': 'Danh sách tòa nhà' }]
        }
      }, {
        path: '/contract',
        name: 'contract',
        component: () =>
          import('../views/dg6789admin6868/contract/ContractList.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/contract', name: 'Hợp đồng', status: false }]
        },
      }, {
        path: '/contract-create',
        name: 'contract-create',
        component: () =>
          import('../views/dg6789admin6868/contract/ContractCreate.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/contract', name: 'Hợp đồng', status: false }]
        },
      }, {
        path: '/contract-detail',
        name: 'contract-detail',
        component: () =>
          import('../views/dg6789admin6868/contract/ContractDetail.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/contract', name: 'Hợp đồng', status: false }]
        },
      }, {
        path: '/contract-view',
        name: 'contract-view',
        component: () =>
          import('../views/dg6789admin6868/contract/ContractViews.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '', name: 'Chi tiết Hợp đồng', status: false }]
        },
      }, {
        path: '/contract-print',
        name: 'contract-print',
        component: () =>
          import('../views/dg6789admin6868/contract/ContractPrint.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '', name: 'In Hợp đồng', status: false }]
        },
      }, {
        path: '/manages/contract-list',
        name: 'manages-contract-list',
        component: () =>
          import('../views/dg6789admin6868/admin/contract/ContractList.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '', name: 'Danh sách mẫu in', status: false }]
        },
      }, {
        path: '/manages/contract-create',
        name: 'manages-contract-create',
        component: () =>
          import('../views/dg6789admin6868/admin/contract/ContractCreate.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '', name: 'Tạo mẫu in', status: false }]
        },
      }, {
        path: '/manages/contract-detail',
        name: 'manages-contract-detail',
        component: () =>
          import('../views/dg6789admin6868/admin/contract/ContractDetail.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '', name: 'Chi tiết hợp đồng', status: false }]
        },
      }, {
        path: '/manages/contract-view',
        name: 'manages-contract-view',
        component: () =>
          import('../views/dg6789admin6868/admin/contract/ContractView.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '', name: 'Xem hợp đồng', status: false }]
        },
      }, {
        path: '/area-detail',
        name: 'area-detail',
        component: () =>
          import('../views/dg6789admin6868/area/AreaDetail.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/area', name: 'Vùng miền', status: false }]
        },
      }, {
        path: '/area-create',
        name: 'area-create',
        component: () =>
          import('../views/dg6789admin6868/area/AreaCreate.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/area', name: 'Vùng miền', status: false }]
        },
      }, {
        path: '/room',
        name: 'room',
        component: () =>
          import('../views/dg6789admin6868/room/RoomList.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/room', name: 'Danh sách phòng', status: false }]
        },
      }, {
        path: '/room-detail',
        name: 'room-detail',
        component: () =>
          import('../views/dg6789admin6868/room/RoomDetail.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/room', name: 'Chi tiết phòng', status: false }]
        },
      }, {
        path: '/room-inform',
        name: 'roomInform',
        component: () =>
          import('../views/dg6789admin6868/admin/manageRoom/RoomInform.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/room-inform', name: 'Thông tin phòng', status: false }]
        },
      }, {
        path: '/room-create',
        name: 'room-create',
        component: () =>
          import('../views/dg6789admin6868/room/RoomCreate.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/room', name: 'Tạo phòng', status: false }]
        },
      }, {
        path: '/room-status',
        name: 'room-status',
        component: () =>
          import('../views/dg6789admin6868/room/RoomStatus.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/room', name: 'Trạng thái phòng', status: false }]
        },
      }, {
        path: '/service',
        name: 'service',
        component: () =>
          import('../views/dg6789admin6868/service/ServiceList.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/service', name: 'Quản lý dịch vụ', status: false }]
        },
      }, {
        path: '/services',
        name: 'services',
        component: () =>
          import('../views/dg6789admin6868/service/ServicesList.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/service', name: 'Quản lý dịch vụ', status: false }]
        },
      }, {
        path: '/service-room',
        name: 'service-room',
        component: () =>
          import('../views/dg6789admin6868/service/ServiceRoomList.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/service-room', name: 'Quản lý dịch vụ theo phòng', status: false }]
        },
      }, {
        path: '/service-create',
        name: 'service-create',
        component: () =>
          import('../views/dg6789admin6868/service/ServiceCreate.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/service', name: 'Quản lý dịch vụ', status: false }]
        },
      }, {
        path: '/service-detail',
        name: 'service-detail',
        component: () =>
          import('../views/dg6789admin6868/service/ServiceDetail.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/service', name: 'Quản lý dịch vụ', status: false }]
        },
      }, {
        path: '/people',
        name: 'people',
        component: () =>
          import('../views/dg6789admin6868/people/People.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/people', name: 'Quản lý người thuê', status: false }]
        },
      }, {
        path: '/order',
        name: 'order',
        component: () =>
          import('../views/dg6789admin6868/order/OrderList.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '', name: 'Quản lý hóa đơn', status: false }]
        },
      }, {
        path: '/order-view',
        name: 'order-view',
        component: () =>
          import('../views/dg6789admin6868/order/ViewAllOrder.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '', name: 'Quản lý hóa đơn', status: false }]
        },
      }, {
        path: '/order-detail',
        name: 'order-detail',
        component: () =>
          import('../views/dg6789admin6868/order/ViewOrder.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '', name: 'Chi tiết hóa đơn', status: false }]
        },
      }, {
        path: '/pay',
        name: 'pay',
        component: () =>
          import('../views/dg6789admin6868/pay/Pay.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '', name: 'Quản lý thanh toán', status: false }]
        },
      }, {
        path: '/setting',
        name: 'setting',
        component: () =>
          import('../views/dg6789admin6868/setting/Setting.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '', name: 'Quản lý cài đặt', status: false }]
        },
      }, {
        path: '/manage-room',
        name: 'manageroom',
        component: () =>
          import('../views/dg6789admin6868/admin/manageRoom/ManageRoom.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '', name: 'Quản lý thu', status: false }]
        },
      }, {
        path: '/table',
        name: 'table',
        component: () =>
          import('../views/dg6789admin6868/table/TableRow.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '', name: 'Bảng hàng trống', status: false }]
        },
      }, {
        path: '/policy',
        name: 'policy',
        component: () =>
          import('../views/dg6789admin6868/policy/Policy.vue'),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '', name: 'Chính sách bảo mật', status: false }]
        },
      }, {
        path: '/dashboard',
        name: 'dashboard',
        component: () =>
          import('../views/dg6789admin6868/dashboard/Dashboard.vue'),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '', name: 'Chính sách bảo mật', status: false }]
        },
      }, {
        path: '/technical',
        name: 'technical',
        component: () =>
          import('../views/dg6789admin6868/technical/TechnicalList.vue'),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '', name: 'Danh sách thông báo kỹ thuật', status: false }]
        },
      },
      {
        path: '/blogs',
        name: 'blogs',
        component: () =>
          import('../views/dg6789admin6868/blogs/BlogList.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/blogs', name: 'Bài viết hướng dẫn', status: false }]
        },
      },{
        path: '/blogs-create',
        name: 'blogs-create',
        component: () =>
          import('../views/dg6789admin6868/blogs/BlogCreate.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/blogs', name: 'Tạo mới', status: false }]
        },
      }, {
        path: '/blogs-detail',
        name: 'blogs-detail',
        component: () =>
          import('../views/dg6789admin6868/blogs/BlogDetail.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/blogs', name: 'Chi tiết', status: false }]
        },
      }, {
        path: '/blogs-view',
        name: 'blogs-view',
        component: () =>
          import('../views/dg6789admin6868/blogs/BlogView.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/blogs', name: 'Xem', status: false }]
        },
      }, {
        path: '/voucher',
        name: 'voucher',
        component: () =>
          import('../views/dg6789admin6868/admin/voucher/Voucher.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/voucher', name: 'Voucher', status: false }]
        },
      }

    ]

  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import('../views/dg6789admin6868/login/Login.vue'),
    meta: {
      requiresUnauth: true
    }
  },
  // {
  //   path: '/indexHome',
  //   name: 'indexHome',
  //   component: () =>
  //     import('../views/dg6789admin6868/client/Index.vue'),
  //   meta: {
  //     requiresUnauth: true
  //   },
  //   children: [
  //     {
  //       path: '/',
  //       name: 'top',
  //       component: () =>
  //         import('../views/dg6789admin6868/client/home/Home.vue'),
  //       meta: {
  //         requiresAuth: false,
  //         breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }]
  //       },
  //     }
  //   ]
  // },
  client,
  {
    path: '/:pathMatch(.*)*',
    name: '*',
    component: () =>
      import('../views/404.vue'),
    meta: {
      requiresUnauth: true
    }
  }, {
    path: '/policy',
    name: 'policy',
    component: () =>
      import('../views/dg6789admin6868/policy/Policy.vue'),
    meta: {
      requiresAuth: false,
      breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '', name: 'Chính sách bảo mật', status: false }]
    },
  },

]
export default routesAdmin
