const client = {
    path: "",
    name: "indexHome",
    component: () => import("../views/client/Index.vue"),
    meta: {
      requiresUnauth: true,
    },
    children: [
      {
        path: "",
        name: "homeIndex",
        component: () => import("../views/client/home/Home.vue"),
        meta: {
          requiresAuth: false,
        },
      },
    //   {
    //     path: '/inform',
    //     name: 'inform',
    //     component: () =>
    //       import('../views/client/user/UserInform.vue'),
    //     meta: {
    //       requiresAuth: true,
    //       breadcrumb: [{ './': 'Home', '/user': 'Người sử dung' }]
    //     }
    //   },
    //   {
    //     path: "/roulette",
    //     name: "roulette",
    //     component: () => import("../views/client/roulte/ViRoultte.vue"),
    //     meta: {
    //       requiresAuth: false,
    //       breadcrumb: [{ './': 'Trang chủ', '/roulette': 'Quay thưởng' }]
    //     },
    //   },
      {
        path: "/favourite",
        name: "favourite",
        component: () => import("../views/client/favourite/Favourite.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ './': 'Trang chủ', '/favourite': 'Yêu thích' }]
        },
      },
    //   {
    //     path: "/scans",
    //     name: "scans",
    //     component: () => import("../views/client/qr_code/QRCode.vue"),
    //     meta: {
    //       requiresAuth: true,
    //       breadcrumb: [{ './': 'Trang chủ', '/scans': 'Quét mã dự thưởng' }]
    //     },
    //   },
      {
        path: "/search",
        name: "search",
        component: () => import("../views/client/search/Search.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ './': 'Trang chủ', '/search': 'Tìm kiếm' }]
        },
      },
      {
        path: "/searchs",
        name: "searchs",
        component: () => import("../views/client/search/Searchs.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ './': 'Trang chủ', '/searchs': 'Tìm kiếm' }]
        },
      },
      {
        path: "/post-news",
        name: "post-news",
        component: () => import("../views/client/product/ProductCreateNews.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ './': 'Trang chủ', '/post-news': 'Đăng tin' }]
        },
      },
      {
        path: "/post-detail",
        name: "post-detail",
        component: () => import("../views/client/product/new/NewsDetail.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ './': 'Trang chủ', '/post-detail': 'Đăng tin' }]
        },
      },
      {
        path: "/post-auto",
        name: "post-auto",
        component: () => import("../views/client/product/new/NewsDetailAuto.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ './': 'Trang chủ', '/post-auto': 'Đăng tin' }]
        },
      },
      {
        path: "/news-status",
        name: "news-status",
        component: () => import("../views/client/product/ProductNewsStatus.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ './': 'Trang chủ', '/post-auto': 'Đăng tin' }]
        },
      },
      {
        path: "/transaction",
        name: "transaction",
        component: () => import("../views/client/product/new/utilities/HistoryTransaction.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ './': 'Trang chủ', '/transaction': 'Đăng tin' }]
        },
      },
      {
        path: "/buy-news",
        name: "buy-news",
        component: () => import("../views/client/product/new/utilities/BuyNewsNumber.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ './': 'Trang chủ', '/buy-news': 'Đăng tin' }]
        },
      },
      {
        path: "/user-informs",
        name: "user-informs",
        component: () => import("../views/client/product/new/user_inform/UserInform.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ './': 'Trang chủ', '/user-informs': 'Đăng tin' }]
        },
      },
      {
        path: "/notifocation",
        name: "notifocation",
        component: () => import("../views/client/product/new/user_inform/Notifocation.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ './': 'Trang chủ', '/notifocation': 'Đăng tin' }]
        },
      },
      {
        path: "/changePassword",
        name: "changePassword",
        component: () => import("../views/client/product/new/user_inform/ChangePassword.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ './': 'Trang chủ', '/changePassword': 'Đăng tin' }]
        },
      },
      {
        path: "/register-vip",
        name: "register-vip",
        component: () => import("../views/client/product/new/vip/VipRegister.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ './': 'Trang chủ', '/changePassword': 'Đăng tin' }]
        },
      },
      {
        path: "/vip-detail",
        name: "vip-detail",
        component: () => import("../views/client/product/new/vip/VipDetails.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ './': 'Trang chủ', '/changePassword': 'Đăng tin' }]
        },
      },
      {
        path: "/news-detail",
        name: "news-detail",
        component: () => import("../views/client/product/new/vip/VipNewsDetails.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ './': 'Trang chủ', '/changePassword': 'Đăng tin' }]
        },
      },
      {
        path: "/news-edit",
        name: "news-edit",
        component: () => import("../views/client/product/new/vip/VipNewsDetails.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ './': 'Trang chủ', '/changePassword': 'Đăng tin' }]
        },
      },
    //   {
    //     path: "/invoice",
    //     name: "invoice",
    //     component: () => import("../views/client/cart/Invoice.vue"),
    //     meta: {
    //       requiresAuth: false,
    //       breadcrumb: [{ './': 'Trang chủ', '/invoice': 'Hóa đơn' }]
    //     },
    //   },
    //   {
    //     path: "/guide",
    //     name: "guide",
    //     component: () => import("../views/client/home/PopupNew.vue"),
    //     meta: {
    //       requiresAuth: false,
    //       breadcrumb: [{ './': 'Trang chủ', '/scans': 'Quét mã dự thưởng' }]
    //     },
    //   },
    //   {
    //     path: "/qr-code",
    //     name: "qr-code",
    //     component: () => import("../views/client/qr_code/MyQRCode.vue"),
    //     meta: {
    //       requiresAuth: true,
    //       breadcrumb: [{ './': 'Trang chủ', '/qr-code': 'Mã QR code' }]
    //     },
    //   },
    //   {
    //     path: "/products",
    //     name: "products",
    //     component: () => import("../views/client/product/Product.vue"),
    //     meta: {
    //       requiresAuth: false,
    //       breadcrumb: [{ './': 'Trang chủ', '/products': 'Sản phẩm' }]
    //     },
    //   },
    //   {
    //     path: "/products-detail",
    //     name: "products-detail",
    //     component: () => import("../views/client/product/ProductDetail.vue"),
    //     meta: {
    //       requiresAuth: false,
    //       breadcrumb: [{ './': 'Trang chủ', '/products': 'Sản phẩm', '/products-detail': 'Chi tiết sản phẩm' }]
    //     },
    //   },
    //   {
    //     path: "/products-code",
    //     name: "products-code",
    //     component: () => import("../views/client/product/ProductDetailQrCode.vue"),
    //     meta: {
    //       requiresAuth: false,
    //       breadcrumb: [{ './': 'Trang chủ', '/products': 'Sản phẩm', '/products-code': 'Chi tiết sản phẩm' }]
    //     },
    //   },
    //   {
    //     path: "/category",
    //     name: "category",
    //     component: () => import("../views/client/product/ProductHome.vue"),
    //     meta: {
    //       requiresAuth: false,
    //       breadcrumb: [{ './': 'Trang chủ', '/products': 'Sản phẩm' }]
    //     },
    //   },
      {
        path: "/news",
        name: "news",
        component: () => import("../views/client/news/NewsList.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ './': 'Trang chủ', '/news': 'Tin tức' }]
        },
      },

      {
        path: "/details",
        name: "details",
        component: () => import("../views/client/news/NewsDetails.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ './': 'Trang chủ', '/news': 'Tin tức', '/details': 'Chi tiết tin' }]
        },
      },
      {
        path: "/search-advanced",
        name: "search-advanced",
        component: () => import("../views/client/product/new/PageSearch.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ './': 'Trang chủ', '/news': 'Tin tức', '/search-advanced': 'Chi tiết tin' }]
        },
      },
    //   {
    //     path: "/cart",
    //     name: "cart",
    //     component: () => import("../views/client/cart/Carts.vue"),
    //     meta: {
    //       requiresAuth: false,
    //       breadcrumb: [{ './': 'Trang chủ', '/news': 'Giỏ hàng' }]
    //     },
    //   },
    //   {
    //     path: "/orders",
    //     name: "orders",
    //     component: () => import("../views/client/cart/CartOrder.vue"),
    //     meta: {
    //       requiresAuth: true,
    //       breadcrumb: [{ './': 'Trang chủ', '/orders': 'Giỏ hàng' }]
    //     },
    //   },
    //   {
    //     path: "/finishs",
    //     name: "finishs",
    //     component: () => import("../views/client/cart/CartFinish.vue"),
    //     meta: {
    //       requiresAuth: false,
    //       breadcrumb: [{ './': 'Trang chủ', '/orders': 'Giỏ hàng' }]
    //     },
    //   },
    //   {
    //     path: "/view-order/:code",
    //     name: "view-order",
    //     component: () => import("../views/client/cart/CartView.vue"),
    //     meta: {
    //       requiresAuth: false,
    //       breadcrumb: [{ './': 'Trang chủ', '/view-order': 'Xem chi tiết' }]
    //     },
    //   },

    //   {
    //     path: "/objects",
    //     name: "objects",
    //     component: () => import("../views/client/rules/Object.vue"),
    //     meta: {
    //       requiresAuth: false,
    //       breadcrumb: [{ './': 'Trang chủ', '/objects': 'Thể lệ tham gia' }]
    //     },
    //   },
      {
        path: "/contact",
        name: "contact",
        component: () => import("../views/client/contact/Contact.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ './': 'Trang chủ', '/contact': 'Liên hệ' }]
        },
      },
      {
        path: "/sell",
        name: "sell",
        component: () => import("../views/client/product/new/page/Sell.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ './': 'Trang chủ', '/sell': 'Nhà đất bán' }]
        },
      },
      {
        path: "/office",
        name: "office",
        component: () => import("../views/client/product/new/page/Office.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ './': 'Trang chủ', '/office': 'Văn phòng' }]
        },
      },
      {
        path: "/room",
        name: "room",
        component: () => import("../views/client/product/new/page/Room.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ './': 'Trang chủ', '/room': 'Phòng trọ' }]
        },
      },
      {
        path: "/lease",
        name: "lease",
        component: () => import("../views/client/product/new/page/Lease.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ './': 'Trang chủ', '/lease': 'Nhà đất cho thuê' }]
        },
      },
      {
        path: "/house",
        name: "house",
        component: () => import("../views/client/product/new/page/House.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ './': 'Trang chủ', '/house': 'Nhà riêng' }]
        },
      },
      {
        path: "/apartment",
        name: "apartment",
        component: () => import("../views/client/product/new/page/Apartment.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ './': 'Trang chủ', '/apartment': 'Căn hộ' }]
        },
      },
    //   {
    //     path: "/policy",
    //     name: "policy",
    //     component: () => import("../views/client/rules/Policy.vue"),
    //     meta: {
    //       requiresAuth: false,
    //       breadcrumb: [{ './': 'Trang chủ', '/policy': 'Chính sách bảo mật' }]
    //     },
    //   },
    //   {
    //     path: "/purview",
    //     name: "purview",
    //     component: () => import("../views/client/rules/Purview.vue"),
    //     meta: {
    //       requiresAuth: false,
    //       breadcrumb: [{ './': 'Trang chủ', '/purview': 'Điều khoản sử dụng' }]
    //     },
    //   },
    //   {
    //     path: "/support",
    //     name: "support",
    //     component: () => import("../views/client/support/Support.vue"),
    //     meta: {
    //       requiresAuth: false,
    //       breadcrumb: [{ './': 'Trang chủ', '/support': 'Hỗ trợ' }]
    //     },
    //   },
    //   {
    //     path: "/refun",
    //     name: "refun",
    //     component: () => import("../views/client/rules/Refun.vue"),
    //     meta: {
    //       requiresAuth: false,
    //       breadcrumb: [{ './': 'Trang chủ', '/refun': 'Chính sách hoàn trả' }]
    //     },
    //   },
    //   {
    //     path: "/finish",
    //     name: "finish",
    //     component: () => import("../views/client/cart/CartFinish.vue"),
    //     meta: {
    //       requiresAuth: true,
    //       breadcrumb: [{ './': 'Trang chủ', '/news': 'Giỏ hàng' }]
    //     },
    //   },
    //   {
    //     path: "/details",
    //     name: "details",
    //     component: () => import("../views/client/news/NewsDetails.vue"),
    //     meta: {
    //       requiresAuth: false,
    //       breadcrumb: [{ './': 'Trang chủ', '/news': 'Tin tức', '/details': 'Chi tiết tin' }]
    //     },
    //   },
    //   {
    //     path: "/points",
    //     name: "points",
    //     component: () => import("../views/client/save_point/SavePoint.vue"),
    //     meta: {
    //       requiresAuth: true,
    //     },
    //   },
    //   {
    //     path: "/sell",
    //     name: "sell",
    //     component: () => import("../views/client/sell/Sell.vue"),
    //     meta: {
    //       requiresAuth: false,
    //     },
    //   },
    //   {
    //     path: "/gift",
    //     name: "gift",
    //     component: () => import("../views/client/gift/Gift.vue"),
    //     meta: {
    //       requiresAuth: false,
    //     },
    //   },
    //   {
    //     path: "/rules",
    //     name: "rules",
    //     component: () => import("../views/client/rules/Rules.vue"),
    //     meta: {
    //       requiresAuth: false,
    //     },
    //   },
    //   {
    //     path: "/academy",
    //     name: "academy",
    //     component: () => import("../views/client/academy/Academy.vue"),
    //     meta: {
    //       requiresAuth: false,
    //     },
    //   },
    //   {
    //     path: "/registers",
    //     name: "registers",
    //     component: () => import("../views/client/user/RegisterForm.vue"),
    //     meta: {
    //       requiresAuth: false,
    //     },
    //   },
    //   {
    //     path: "/password",
    //     name: "password",
    //     component: () => import("../views/client/user/ChangePassword.vue"),
    //     meta: {
    //       requiresAuth: false,
    //     },
    //   },
    //   {
    //     path: "/passwordOpt",
    //     name: "passwordOpt",
    //     component: () => import("../views/client/user/ChangePasswordOpt.vue"),
    //     meta: {
    //       requiresAuth: false,
    //     },
    //   },
    //   {
    //     path: "/user-code/:id",
    //     name: "user-code",
    //     component: () => import("../views/client/user/UserInformQRCode.vue"),
    //     meta: {
    //       requiresAuth: false,
    //     },
    //   },
    //   {
    //     path: "/setting",
    //     name: "setting",
    //     component: () => import("../views/client/setting/Setting.vue"),
    //     meta: {
    //       requiresAuth: false,
    //     },
    //   },
    //   {
    //     path: "/opt",
    //     name: "opt",
    //     component: () => import("../views/client/user/OptCode.vue"),
    //     meta: {
    //       requiresAuth: false,
    //     },
    //   },
    //   {
    //     path: "/opts",
    //     name: "opts",
    //     component: () => import("../views/client/user/OptCodePass.vue"),
    //     meta: {
    //       requiresAuth: false,
    //     },
    //   },
    //   {
    //     path: "/opt-email",
    //     name: "opt-email",
    //     component: () => import("../views/client/user/OptCodePassEmail.vue"),
    //     meta: {
    //       requiresAuth: false,
    //     },
    //   },
      {
        path: '/success',
        name: 'success',
        component: () =>
          import('../views/client/product/new/page/Success.vue'),
        meta: {
          requiresUnauth: false
        }
      },
      {
        path: '/registerNews',
        name: 'registerNews',
        component: () =>
          import('../views/client/user/form/RegisterForm.vue'),
        meta: {
          requiresUnauth: false
        }
      },
    ],
  };
  
  export default client;
  