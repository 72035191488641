export const USER_IP = 'local'
export const TOKEN_GOOGLE = 'TG'
export const LOAD_LIB_JS = 'loadJs'
export const LOCALSSTORE_BUILD_ID = 'buildingId'
export const LOCALSSTORE_BUILD_NAME = 'buildingName'
/** Common */
export const CLIENT_MESSAGE_ID = 'clentMessageId'
/** user */
export const USER = 'user'
export const USER_VIEW_IFNORM_LC = '6093451341432198'
export const USER_INTRO_LC = '6091443012432198'
export const USER_QRCODE_LC = '5831443301432198'
export const STORE_LANGUAGE = 'languageSelected'
export const USER_INFORM_LS = '1009675463804809'
export const USER_FULL_NAME_LS = '8119850409372729'
export const USER_STATUS_LS = '6091443061700198'
export const USER_LOGON_LS = '1912585177012726'
export const USER_NAME_LS = '7976563185591283'
export const USER_EXPIRED_DATE_LS = '7976586951594283'
export const SESIONID_INFORM_LS = '7209705825757861'
export const OPT_INFORM_LS = '7976563195594283'
/** end user */
export const TOKEN_INFORM_LS = '1939352956188920'
/** roll */
export const ROULETTE_POINT_LS = '2139359256188920'
export const POINT_USER_LS = '2339352569188920'
export const WHELL_CHECK_LS = '23399796534188920'
/** cart */
export const CART_ORDER_LC = '3452342956188920'
export const FAVOURITE_LC = '3452342506896372'
/** search */
export const SEARCH_DATA_LC = '3452304255643220'
/** cart */
export const CART_LIST = '3409876210243220'
export const CART_ORDER_BY = '3456807621243220'
export const NEWS_CLIENT_ID = '3456563456243220'
export const NEWS_AUTO_CLIENT_ID = '345656341562423220'
/** new */
export const NEWS_REGISTER_VIP = '4356304255643220'
